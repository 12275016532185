<template>
  <div class="ApproveArticle">
    <p class="title">审批物品申请-列表</p>
    <div class="content">
      <div class="below">
        <div>
          <el-input class="inputCla" v-model="apply_user_name" placeholder="请输入申请人"></el-input>
        </div>
        <div>
          <el-input class="inputCla" v-model="shipping_code" placeholder="请输入快递单号"></el-input>
        </div>
        <div class="cityProvince">
          <el-select
            v-model="apply_status"
            placeholder="请选择状态"
            size="small"
            filterable
            clearable
          >
            <el-option
              v-for="item in apply_status_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="calendarone">
          <div class="title">时间:</div>
        </div>
        <timePickerCloud :init="false" :end_stats_dayOld="end_stats_day" :start_stats_dayOld="start_stats_day" @setTime="setTime"></timePickerCloud>
        <div class="seachone">
          <el-button type="primary" size="small" @click="apply_article_listFun">
            搜索
          </el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe height="640" v-loading="loading">
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="apply_id"
          label="申请单号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_date"
          label="申请时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_user_name"
          label="申请人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_flow"
          label="物品流向"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="organization_name"
          label="申请单位"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="apply_status"
          label="当前状态"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="doSomeThing(scope.row)"
            >
              处理
            </el-button>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="confireDele(scope.row)"
            >
              <el-button type="danger" 
              size="small" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import apiDetail from '@/api/shenqing.js'
import timePickerCloud from '../../components/timePickerCloud.vue'

export default {
  components: {
    timePickerCloud,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      apply_user_name: '',//申请人
      shipping_code: '',//快递单号
      apply_status: '1',//发货状态
      start_stats_day: '', //开始日期
      end_stats_day: '', //结束日期
      total: 0,
      page: 1,
      size:10,
      apply_status_list: [
        {
          name: '已驳回',
          id: '0'
        },
        {
          name: '待审批',
          id: '1'
        },
        {
          name: '待发货',
          id: '2'
        },
        {
          name: '已发货',
          id: '3'
        },
        {
          name: '已完成',
          id: '4'
        },
      ],
    }
  },
  mounted() {
    this.apply_article_listFun()
  },
  methods: {
    // 物品申请审批列表
    apply_article_listFun() {
      let start_time = '',
          end_time = '';
      if(this.start_stats_day&& this.end_stats_day){
        start_time = `${this.start_stats_day.substring(0,4)}-${this.start_stats_day.substring(4,6)}-${this.start_stats_day.substring(6,8)}`
        end_time = `${this.end_stats_day.substring(0,4)}-${this.end_stats_day.substring(4,6)}-${this.end_stats_day.substring(6,8)}`
      }
      let param = {
        page: this.page,
        size: this.size,
        start_time,
        end_time,
        apply_user_name: this.apply_user_name,
        shipping_code: this.shipping_code,
        apply_status: this.apply_status,
      }
      apiDetail.apply_article_list(param).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.count
      })
    },
    // 设置时间
    setTime(val) {
      this.start_stats_day = val.start_stats_day;
      this.end_stats_day = val.end_stats_day;
    },
    //  翻页
    handleCurrentChange(value) {
      this.page = value
      this.apply_article_listFun()
    },

    // 处理
    doSomeThing(item) {
      let url = `articleapplis-detail?id=${item.apply_id}&isShowFun=${true}`
      window.open(url, '_blank'); 
    },
    // 删除按钮
    confireDele(item) {
      apiDetail.delete_article_apply({apply_id:item.apply_id}).then(res=>{
        if(res.code == 200) {
           this.$message.success("删除成功")
          this.apply_article_listFun();
        }else{
           this.$message.success(res.msg)
        }
      })
    },
    // 搜索按钮
    searchBtn() {

    },
  },
}
</script>
<style lang="scss" scoped>
.ApproveArticle {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    // .btnBox {
    //   text-align: right;
    //   margin-bottom: 20px;
    // }
    .below {
      margin-bottom: 20px;
      height: px(36);
      display: flex;
      &>div{
        margin-right: 10px;
      }
      .title{
        font-size: 15px;
        font-weight: 100;
        color: #111111;
        margin-bottom: 20px;
      }
      .inputCla{
        ::v-deep .el-input,
            ::v-deep .el-input__inner {
                width: px(200);
                height: 30px;
            }
      }
    }
  }
}
</style>
