import request from '@/utils/request'
const api = {
    // 物品申请审批列表
    apply_article_list(params) {
        return request({
            url: '/api/apply_article_list/',
            method: 'get',
            params
        })
    },
    // 物品申请审批列表
    delete_article_apply(data) {
        return request({
            url: '/api/del_article_apply/',
            method: 'post',
            data
        })
    },

}
export default api